import React from 'react'

export const Close = () => (
    <svg viewBox="0 0 512 512" className="icon-fill">
        <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z" />
    </svg>
)

export const Next = () => (
    <svg viewBox="0 0 170 174" className="icon-fill">
        <path d="M132.916 87L20 153.684L20 20.316L132.916 87Z" />
        <rect x="132.916" y="20" width="16.6257" height="134" />
    </svg>
)

export const Previous = () => (
    <svg viewBox="0 0 170 174" className="icon-fill">
        <path d="M36.6257 87L149.542 20.316L149.542 153.684L36.6257 87Z" />
        <rect x="36.6257" y="154" width="16.6257" height="134" transform="rotate(-180 36.6257 154)" />
    </svg>
)

export const Shuffle = () => (
    <svg x="0px" y="0px" viewBox="0 0 512 512" className="icon-fill">
        <g>
            <g>
                <g>
                    <path
                        d="M21.333,149.327H64c18.773,0,37.227,4.928,53.333,14.272c3.371,1.963,7.061,2.88,10.688,2.88
				c7.36,0,14.528-3.819,18.475-10.624c5.931-10.197,2.432-23.253-7.744-29.163C116.117,113.594,90.283,106.66,64,106.66H21.333
				C9.536,106.66,0,116.218,0,127.994S9.536,149.327,21.333,149.327z"
                    />
                    <path
                        d="M320,149.327h42.667v64c0,8.192,4.715,15.68,12.075,19.221c2.965,1.408,6.123,2.112,9.259,2.112
				c4.757,0,9.472-1.6,13.333-4.672L504,144.655c5.056-4.053,8-10.176,8-16.661c0-6.485-2.944-12.608-8-16.661L397.333,25.999
				c-6.421-5.12-15.232-6.101-22.592-2.56s-12.075,11.029-12.075,19.221v64H320c-82.325,0-149.333,66.987-149.333,149.333
				c0,58.816-47.851,106.667-106.667,106.667H21.333C9.536,362.66,0,372.218,0,383.994s9.536,21.333,21.333,21.333H64
				c82.325,0,149.333-66.987,149.333-149.333C213.333,197.178,261.184,149.327,320,149.327z"
                    />
                    <path
                        d="M504,367.336l-106.667-85.333c-6.421-5.141-15.232-6.123-22.592-2.581c-7.36,3.563-12.075,11.029-12.075,19.243v64H320
				c-21.077,0-41.472-6.144-58.965-17.771c-9.856-6.485-23.061-3.861-29.568,5.973c-6.528,9.813-3.861,23.061,5.952,29.568
				c24.512,16.277,53.056,24.896,82.581,24.896h42.667v64c0,8.192,4.715,15.68,12.075,19.221c2.965,1.408,6.123,2.112,9.259,2.112
				c4.757,0,9.472-1.6,13.333-4.672L504,400.659c5.056-4.053,8-10.197,8-16.661C512,377.512,509.056,371.368,504,367.336z"
                    />
                </g>
            </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
    </svg>
)

export const Connect = () => (
    <svg viewBox="0 0 123 118" className="icon-connect">
        <path
            d="M50 10H14C11.7909 10 10 11.7909 10 14V80C10 82.2091 11.7909 84 14 84H50"
            strokeWidth="8"
            strokeLinejoin="round"
        />
        <rect x="61" y="10" width="52" height="98" rx="6" strokeWidth="8" />
        <path d="M35 108H50" strokeWidth="8" />
        <circle cx="87" cy="77" r="14" strokeWidth="8" />
        <circle cx="87" cy="35" r="5" className="icon-fill" />
    </svg>
)

export const Repeat = () => (
    <svg x="0px" y="0px" viewBox="0 0 511.991 511.991" className="icon-fill">
        <g>
            <g>
                <g>
                    <path
                        d="M465.45,279.263c-12.87,0-23.273,10.426-23.273,23.273c0,38.493-31.325,69.818-69.818,69.818H186.177v-69.818
				c0-8.937-5.143-17.105-13.172-20.969c-8.122-3.863-17.641-2.793-24.646,2.793L31.995,377.451
				c-5.516,4.422-8.727,11.101-8.727,18.176c0,7.075,3.212,13.754,8.727,18.176l116.364,93.091
				c4.212,3.351,9.356,5.097,14.545,5.097c3.421,0,6.889-0.768,10.1-2.304c8.029-3.863,13.172-12.032,13.172-20.969V418.9h186.182
				c64.163,0,116.364-52.201,116.364-116.364C488.723,289.689,478.32,279.263,465.45,279.263z"
                    />
                    <path
                        d="M46.541,232.718c12.87,0,23.273-10.426,23.273-23.273c0-38.493,31.325-69.818,69.818-69.818h186.182v69.818
				c0,8.937,5.143,17.105,13.172,20.969c3.235,1.536,6.679,2.304,10.1,2.304c5.19,0,10.333-1.745,14.545-5.097l116.364-93.091
				c5.516-4.422,8.727-11.101,8.727-18.176s-3.212-13.754-8.727-18.176L363.632,5.087c-6.982-5.585-16.617-6.656-24.646-2.793
				s-13.172,12.032-13.172,20.969v69.818H139.632c-64.163,0-116.364,52.201-116.364,116.364
				C23.268,222.292,33.671,232.718,46.541,232.718z"
                    />
                </g>
            </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
    </svg>
)

export const Speaker = () => (
    <svg viewBox="0 0 76 120">
        <rect x="12" y="11" width="52" height="98" rx="4" strokeWidth="6" />
        <circle cx="38" cy="78" r="14" strokeWidth="6" />
        <circle cx="38" cy="36" r="5" className="icon-fill" />
    </svg>
)

export const Phone = () => (
    <svg viewBox="0 0 76 120">
        <rect x="12" y="11" width="52" height="98" rx="4" strokeWidth="6" />
        <circle cx="38" cy="96" r="5" className="icon-fill" />
    </svg>
)

export const Computer = () => (
    <svg viewBox="0 0 228 180">
        <rect x="35" y="21" width="157" height="113" rx="4" strokeWidth="6" />
        <path d="M15 158H213" strokeWidth="6" />
    </svg>
)

export const Play = () => (
    <svg x="0px" y="0px" viewBox="0 0 320.001 320.001" className="icon-fill">
        <path
            d="M295.84,146.049l-256-144c-4.96-2.784-11.008-2.72-15.904,0.128C19.008,5.057,16,10.305,16,16.001v288
	c0,5.696,3.008,10.944,7.936,13.824c2.496,1.44,5.28,2.176,8.064,2.176c2.688,0,5.408-0.672,7.84-2.048l256-144
	c5.024-2.848,8.16-8.16,8.16-13.952S300.864,148.897,295.84,146.049z"
        />
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
    </svg>
)

export const Pause = () => (
    <svg x="0px" y="0px" viewBox="0 0 47.607 47.607" className="icon-fill">
        <g>
            <path
                d="M17.991,40.976c0,3.662-2.969,6.631-6.631,6.631l0,0c-3.662,0-6.631-2.969-6.631-6.631V6.631C4.729,2.969,7.698,0,11.36,0
		l0,0c3.662,0,6.631,2.969,6.631,6.631V40.976z"
            />
            <path
                d="M42.877,40.976c0,3.662-2.969,6.631-6.631,6.631l0,0c-3.662,0-6.631-2.969-6.631-6.631V6.631
		C29.616,2.969,32.585,0,36.246,0l0,0c3.662,0,6.631,2.969,6.631,6.631V40.976z"
            />
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
    </svg>
)

export const PersonIcon = () => {
    return (
        <svg enableBackground="new 0 0 512 512" viewBox="0 0 512 512">
            <g>
                <path
                    d="M348.574,145.901c0,53.522-43.377,96.914-96.901,96.914c-53.523,0-96.914-43.392-96.914-96.914    c0-53.51,43.391-96.901,96.914-96.901C305.197,49,348.574,92.391,348.574,145.901z"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="20"
                />
                <path
                    d="M275.38,274.46c95.561,9.706,170.154,90.401,170.154,188.54"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="20"
                />
                <path
                    d="M66.466,463c0-96.358,71.925-175.934,165.027-187.961"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="20"
                />
            </g>
        </svg>
    )
}

export const Heart = () => (
    <svg viewBox="0 0 512 512">
        <path d="M340.8,83C307,83,276,98.8,256,124.8c-20-26-51-41.8-84.8-41.8C112.1,83,64,131.3,64,190.7c0,27.9,10.6,54.4,29.9,74.6  L245.1,418l10.9,11l10.9-11l148.3-149.8c21-20.3,32.8-47.9,32.8-77.5C448,131.3,399.9,83,340.8,83L340.8,83z" />
    </svg>
)

export const HeartOutline = () => (
    <svg viewBox="0 0 512 512">
        <path d="M340.8,98.4c50.7,0,91.9,41.3,91.9,92.3c0,26.2-10.9,49.8-28.3,66.6L256,407.1L105,254.6c-15.8-16.6-25.6-39.1-25.6-63.9  c0-51,41.1-92.3,91.9-92.3c38.2,0,70.9,23.4,84.8,56.8C269.8,121.9,302.6,98.4,340.8,98.4 M340.8,83C307,83,276,98.8,256,124.8  c-20-26-51-41.8-84.8-41.8C112.1,83,64,131.3,64,190.7c0,27.9,10.6,54.4,29.9,74.6L245.1,418l10.9,11l10.9-11l148.3-149.8  c21-20.3,32.8-47.9,32.8-77.5C448,131.3,399.9,83,340.8,83L340.8,83z" />
    </svg>
)
